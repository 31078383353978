.avatar{
  width: 100px;
  height: 100px;
  border-radius: 50px;
  margin: auto;
}
.demo-drawer-header{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 16px;
  height: 151px;
  background-color: $color-primary;
}
.h6-navbar{
  color: aliceblue;
}
.logo-navbar{
  height: 50px;
  width: 50px;
}
.mdl-layout__header{
  background-color: $color-primary;
}

#searchBarResult {
  position: absolute;
  top: 35px;
  right: 3px;
  width: 100%;
  background-color: white;
  text-align: left;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    a {
      color: $tertiary;
      li {
        padding: 5px 0;
        transition: 0.3s ease;
        background-color: $primary;
        cursor: pointer;

      }
      li:first-child {
        border-top: 1px solid $tertiary;
      }
      li:not(:last-child) {
        border-bottom: 1px solid white;
      }
      li:hover {
        background-color: $tertiary;
      }
    }
    a:hover {
      color: $primary;
    }
  }
}
