.alert-danger{
  color: #b00000;
  background-color: #926666;
}
.alert-success{
  color: green;
  background-color: darkseagreen;
  display: flow-root;
}
.message-alert{
  margin: 0;
  font-size: 20px;
}