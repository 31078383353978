.show-author{
    display: flex;
    align-items: center;
    margin: 0;
}
.show-avatar{
    height: 70px;
    width: 70px;
    border-radius: 50px;
    margin-right: 2em;
}
.show-author-date{
    margin: 0;
    font-style: italic;
}
.logo-tech{
    height: 70px;
    width: 70px;
}