.center-layout{
    display: block;
}
.logo-index{
    height: 300px;
    width: 300px;
}
.home-title {
    font-size: 4.8rem;
    color: #1b1b33;
    text-transform: uppercase;
}

.home-downtitle {
    font-size: 2rem;
    white-space: nowrap;
    margin-right: 5em;
}

.home-js {
    font-size: 1.8rem;
}

.word {
    position: absolute;
    width: 300px;
    opacity: 0;
}

.letter {
    display: inline-block;
    position: relative;
    float: left;
    transform: translateZ(25px);
    transform-origin: 50% 50% 25px;
}

.letter.out {
    transform: rotateX(90deg);
    transition: transform 0.32s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.letter.behind {
    transform: rotateX(-90deg);
}

.letter.in {
    transform: rotateX(0deg);
    transition: transform 0.38s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.wisteria {
    color: #8e44ad;
}

.belize {
    color: #2980b9;
}

.pomegranate {
    color: #c0392b;
}

.green {
    color: #16a085;
}
.home-presentation{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.color-href{
    color: red;
}