@media screen and (max-width: 767px) {
	.mobile-w-{
		&25 {
			width: 25%;
		}

		&50 {
			width: 50%;
		}

		&75 {
			width: 75%;
		}

		&100 {
			width: 100%;
		}
	}
	.mobile-height-unset {
		height: unset;
		max-height: unset;
		min-height: unset;
	}
	.mobile-display-none{
		display: none;
	}
	.home-downtitle{
		margin-right: 0;
		white-space: normal;
	}
	.mobile-display{
		display: flex;
	}
	div.mobile-form{
		margin-left: 0;
		margin-top: 0;
	}
}

@media all and (min-width: 768px) and (max-width: 1900px){
	nav.mdl-navigation {
		a.mdl-navigation__link.mobile-display {
			display: none;
		}
	}
}