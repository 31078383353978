
.margin-card{
    display: flex;
    justify-content: center;
    margin-top: 3em;
}
div.img-language .mobile-apple {
    display: flex;
    justify-content: center;
    height: 75px;
    width: 75px;
}
.center{
    display: flex;
    flex-direction: column;
    align-items: center;
}

a{
    text-decoration: none;
    color: #0E2231;
}
