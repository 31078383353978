$primary: #1b1b33;
$secondary: #343a40;
$tertiary: snow;

.bck {
	&-primary {
		background-color: $primary;
	}

	&-secondary {
		background-color: $secondary;
	}

	&-tertiary {
		background-color: $tertiary;
	}
}

.text {
	&-primary {
		color: $primary;
	}

	&-secondary {
		color: $secondary;
	}

	&-tertiary {
		color: $tertiary;
	}
}

.text-align {
	&-center {
		text-align: center;
	}

	&-justify {
		text-align: justify;
	}

	&-right {
		text-align: right;
	}

	&-left {
		text-align: left;
	}
}

.d- {
	&flex {
		display: flex;
	}

	&grid {
		display: grid;
	}
}

.width-unset {
	width: unset;
}

.flex-direction {
	&-column {
		flex-direction: column;
	}

	&-row {
		flex-direction: row;
	}
}

.justify-content {
	&-center {
		justify-content: center;
	}

	&-right {
		justify-content: right;
	}

	&-left {
		justify-content: left;
	}

	&-space-evenly {
		justify-content: space-evenly;
	}
}

.align-items {
	&-center {
		align-items: center;
	}
}

.m-0 {
	margin: 0 !important;
}

.mt-0 {
	margin-top: 0 !important;
}

.mr-0 {
	margin-right: 0 !important;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.ml-0 {
	margin-left: 0 !important;
}

.m-1 {
	margin: 0.25rem !important;
}

.mt-1 {
	margin-top: 0.25rem !important;
}

.mr-1 {
	margin-right: 0.25rem !important;
}

.mb-1 {
	margin-bottom: 0.25rem !important;
}

.ml-1 {
	margin-left: 0.25rem !important;
}

.m-2 {
	margin: 0.5rem !important;
}

.mt-2 {
	margin-top: 0.5rem !important;
}

.mr-2 {
	margin-right: 0.5rem !important;
}

.mb-2 {
	margin-bottom: 0.5rem !important;
}

.ml-2 {
	margin-left: 0.5rem !important;
}

.m-3 {
	margin: 1rem !important;
}

.mt-3 {
	margin-top: 1rem !important;
}

.mr-3 {
	margin-right: 1rem !important;
}

.mb-3 {
	margin-bottom: 1rem !important;
}

.ml-3 {
	margin-left: 1rem !important;
}

.m-4 {
	margin: 1.5rem !important;
}

.mt-4 {
	margin-top: 1.5rem !important;
}

.mr-4 {
	margin-right: 1.5rem !important;
}

.mb-4 {
	margin-bottom: 1.5rem !important;
}

.ml-4 {
	margin-left: 1.5rem !important;
}

.m-5 {
	margin: 3rem !important;
}

.mt-5 {
	margin-top: 3rem !important;
}

.mr-5 {
	margin-right: 3rem !important;
}

.mb-5 {
	margin-bottom: 3rem !important;
}

.ml-5 {
	margin-left: 3rem !important;
}

.p-0 {
	padding: 0 !important;
}

.pt-0 {
	padding-top: 0 !important;
}

.pr-0 {
	padding-right: 0 !important;
}

.pb-0 {
	padding-bottom: 0 !important;
}

.pl-0 {
	padding-left: 0 !important;
}

.p-1 {
	padding: 0.25rem !important;
}

.pt-1 {
	padding-top: 0.25rem !important;
}

.pr-1 {
	padding-right: 0.25rem !important;
}

.pb-1 {
	padding-bottom: 0.25rem !important;
}

.pl-1 {
	padding-left: 0.25rem !important;
}

.p-2 {
	padding: 0.5rem !important;
}

.pt-2 {
	padding-top: 0.5rem !important;
}

.pr-2 {
	padding-right: 0.5rem !important;
}

.pb-2 {
	padding-bottom: 0.5rem !important;
}

.pl-2 {
	padding-left: 0.5rem !important;
}

.p-3 {
	padding: 1rem !important;
}

.pt-3 {
	padding-top: 1rem !important;
}

.pr-3 {
	padding-right: 1rem !important;
}

.pb-3 {
	padding-bottom: 1rem !important;
}

.pl-3 {
	padding-left: 1rem !important;
}

.p-4 {
	padding: 1.5rem !important;
}

.pt-4 {
	padding-top: 1.5rem !important;
}

.pr-4 {
	padding-right: 1.5rem !important;
}

.pb-4 {
	padding-bottom: 1.5rem !important;
}

.pl-4 {
	padding-left: 1.5rem !important;
}

.p-5 {
	padding: 3rem !important;
}

.pt-5 {
	padding-top: 3rem !important;
}

.pr-5 {
	padding-right: 3rem !important;
}

.pb-5 {
	padding-bottom: 3rem !important;
}

.pl-5 {
	padding-left: 3rem !important;
}

.m-auto {
	margin: auto !important;
}

.mt-auto {
	margin-top: auto !important;
}

.mr-auto {
	margin-right: auto !important;
}

.mb-auto {
	margin-bottom: auto !important;
}

.ml-auto {
	margin-left: auto !important;
}

h {
	&1 {
		font-family: "Rokkitt", sans-serif;
	}

	&2 {
		font-family: "Rokkitt", sans-serif;
	}

	&3 {
		font-family: "Rokkitt", sans-serif;
	}

	&4 {
		font-family: "Rokkitt", sans-serif;
	}

	&5 {
		font-family: "Rokkitt", sans-serif;
	}

	&6 {
		font-family: "Rokkitt", sans-serif;
	}
}

p {
	font-family: "Ubuntu Mono", sans-serif;
}

label {
	font-family: "Ubuntu Mono", sans-serif;
}

.mdl-layout-title {
	font-family: "Rokkitt", sans-serif;
}

nav.mdl-navigation > a.mdl-navigation__link {
	font-family: "Rokkitt", sans-serif;
	font-size: 18px;
}

main {
	flex: 1 0 auto;
}

.cke_dialog_ui_input_select {
	display: unset;
}

.hljs {
	overflow-x: auto;
	padding: 1em;
}

.content-base {
	display: flex;
	min-height: 80vh;
	flex-direction: column;
	padding-top: 64px;
}

.w- {
	&25 {
		width: 25%;
	}

	&50 {
		width: 50%;
	}

	&75 {
		width: 75%;
	}

	&100 {
		width: 100%;
	}
}