body {
    div{
        .content-base{
            display: block;
        }
    }
    .sheet-language {
        display: flex;
        align-items: center;

    }
    .sheet-avatar {
        height: 50px;
        width: 50px;
        border-radius: 50px;
        margin-right: 0.4em;
    }
    .sheet-author {
        display: flex;
        align-items: flex-end;

        .sheet-image {
            height: 50px;
            width: 50px;
            margin-right: 0.4em;
        }

        p {
            margin-right: 10px;
        }
    }
}
