html, body {
    height: 100%;
    background-color: #F5F9FC;
    font-family: 'Open Sans', sans-serif;
}

.container {
    display: flex;
    align-items: flex-start;
}
.card-container {
    min-height: 260px;
}
.a-cards {
    text-transform: none;
}

.card {
    position: relative;
    display: flex;
    justify-content: center;
    cursor: pointer;
    width: 25em;
    margin: 0 auto;
    min-height: 6em;
    padding: 2em 0;
    background: #FFF;
    box-shadow: 0 0 6px 0 rgba(#202024, .12);
    transition: all .35s ease;

    &::before, &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #000;
        height: 4px;
    }

    &::before {
        width: 0;
        opacity: 0;
        transition: opacity 0s ease, width 0s ease;
        transition-delay: .5s;
    }

    &::after {
        width: 100%;
        background: white;
        transition: width .5s ease;
    }

    .card-image {
        height: 35px;
        width: 35px;
        margin-bottom: 0.2em;
    }

    .content {
        width: 18em;
        max-width: 80%;
    }

    .logo {
        margin: 0 0 1em;
        width: 10.625em;
        transition: all .35s ease;
    }

    h6 {
        color: #999;
        font-weight: 600;
        text-transform: uppercase;
        margin: 0;
        letter-spacing: 2px;
    }

    .hover_content {
        overflow: hidden;
        max-height: 0;
        transform: translateY(1em);
        transition: all .55s ease;

        p {
            margin: 1.5em 0 0.7em;
            color: #6E6E70;
            line-height: 1.4em;
        }

        .author {
            display: flex;
            justify-content: space-around;
        }

        button {
            margin-bottom: 0.7em
        }

        .button {
            display: flex;
            justify-content: flex-end;
        }
    }

    &:hover {
        width: 24em;
        box-shadow: 0 10px 20px 0 rgba(#202024, .12);

        &::before {
            width: 100%;
            opacity: 1;
            transition: opacity .5s ease, width .5s ease;
            transition-delay: 0s;
        }

        &::after {
            width: 0;
            opacity: 0;
            transition: width 0s ease;
        }

        .logo {
            margin-bottom: .5em;
        }

        .hover_content {
            max-height: 25em;
            transform: none;
        }
    }
    .mdl-button--colored{
        background-color: $color-accent;
        color: $primary;
    }
}
.favorite {
    display: block;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2em;
    &-full {
        color: red;
    }
}
.edit-sheet {
    display: block;
    position: absolute;
    top: 45px;
    right: 10px;
    font-size: 2em;
}
